import { GetSettings } from "./get-settings";
import { GetSettingsFailure } from "./get-settings-failure";
import { GetSettingsSuccess } from "./get-settings-success";

export type Actions = GetSettings | GetSettingsFailure | GetSettingsSuccess;

// start:ng42.barrel
export * from "./get-settings-failure";
export * from "./get-settings-success";
export * from "./get-settings";
// end:ng42.barrel

import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class QueryParamsService {
	constructor(private router: Router, private route: ActivatedRoute) {}

	public updateQuery(filters: uwcc.ILabelValue[]): void {
		const url = this.route.snapshot.url;
		const queryParams = {
			...this.route.snapshot.queryParams,
		};

		filters.forEach(filter => queryParams[filter.label] = filter.value);

		this.router.navigate(url, {queryParams});
	}
}

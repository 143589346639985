import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "uwcc-results-total",
	templateUrl: "./results-total.component.html",
	styleUrls: ["./results-total.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsTotalComponent implements OnInit {
	@Input() public pagination: uwcc.IPagination;

	public get first(): number {
		return this.pagination.first_item ? this.pagination.first_item : 0;
	}

	public get last(): number {
		return this.pagination.last_item ? this.pagination.last_item : 0;
	}

	constructor() {}

	ngOnInit() {}
}

import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { RouterStateSerializer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateModule } from "@ngx-translate/core";

import { LocalizeRouterModule } from "localize-router";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { APP_ROUTING } from "./app.routing";
import { AuthModule } from "./auth/auth.module";
import { APP_CONFIG, UWCC_CONFIG } from "./config/uwcc.config";
import { CoreModule } from "./core/core.module";
import { LanguageService } from "./language/language.service";
import { LOCALIZE_ROUTER_MODULE_CONFIG } from "./language/localize-router.module.config";
import { TRANSLATE_MODULE_CONFIG } from "./language/translate.module.config";
import { metaReducers, reducers } from "./reducers";
import { CustomRouterStateSerializer } from "./shared/custom-router-state-serializer";
import { UwccComponent } from "./uwcc/uwcc.component";

registerLocaleData(localeEn, "en");
registerLocaleData(localeFr, "fr");

@NgModule({
	declarations: [AppComponent, UwccComponent],
	imports: [
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(APP_ROUTING),
		StoreModule.forRoot(reducers, { metaReducers }),
		StoreRouterConnectingModule,
		!environment.production ? StoreDevtoolsModule.instrument() : [],
		EffectsModule.forRoot([]),
		LocalizeRouterModule.forRoot(APP_ROUTING, LOCALIZE_ROUTER_MODULE_CONFIG),
		TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
		CoreModule.forRoot(),
		AuthModule.forRoot(),
	],
	providers: [
		LanguageService,
		{ provide: APP_CONFIG, useValue: UWCC_CONFIG },
		{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
		{ provide: LOCALE_ID, useValue: environment.lang },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

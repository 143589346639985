import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "fancyBoolean",
})
export class FancyBooleanPipe implements PipeTransform {
	public transform(value: boolean): string {
		return value ? "✔" : "✖";
	}
}

import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromSettings from "./settings.reducer";

export const FEATURE = "CORE";

export interface ICoreState {
	settings: fromSettings.IState;
}

export const reducers = {
	settings: fromSettings.reducer,
};

export const selectCoreState = createFeatureSelector<ICoreState>(FEATURE);
export const selectCoreSettingsState = createSelector(selectCoreState, state => state.settings);
export const getUrls = createSelector(selectCoreSettingsState, fromSettings.getUrls);

import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
	selector: "uwcc-login-dialog",
	templateUrl: "./login-dialog.component.html",
	styleUrls: ["./login-dialog.component.scss"],
})
export class LoginDialogComponent {
	constructor(public dialogRef: MatDialogRef<LoginDialogComponent>) {}

	public onLogin(form: uwcc_api.ILoginTokenBody): void {
		this.dialogRef.close(form);
	}
}

import * as auth from "../actions";

export interface IState {
	errors: string[] | null;
	pending: boolean;
}

export const initialState: IState = {
	errors: null,
	pending: false,
};

export function reducer(state = initialState, action: auth.Actions): IState {
	switch (action.type) {
		case auth.LOGIN: {
			return {
				...state,
				errors: null,
				pending: true,
			};
		}

		case auth.LOGIN_SUCCESS: {
			return {
				...state,
				errors: null,
				pending: false,
			};
		}

		case auth.LOGIN_FAILURE: {
			return {
				...state,
				errors: action.payload.error.errors,
				pending: false,
			};
		}

		default: {
			return state;
		}
	}
}

export const getError = (state: IState) => state.errors;
export const getPending = (state: IState) => state.pending;

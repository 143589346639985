import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { SettingsEffects } from "./effects/settings.effects";
import { FEATURE, reducers } from "./reducers";
import { ApiService } from "./services/api.service";
import { ConfigService } from "./services/config.service";
import { DataService } from "./services/data.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { QueryParamsService } from "./services/query-params.service";
import { StorageService } from "./services/storage.service";

const SERVICES = [
	ApiService,
	ConfigService,
	DataService,
	StorageService,
	QueryParamsService,
	GoogleAnalyticsService,
];

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE, reducers),
		EffectsModule.forFeature([SettingsEffects]),
	],
	declarations: [],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: CoreModule,
			providers: [SERVICES],
		};
	}
}

import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
	selector: "uwcc-confirm-dialog",
	templateUrl: "./confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {

	public title: string;
	public message: string;
	public cancel: string;
	public confirm: string;
	public confirmColor: string;

	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
	) {
		this.title = this.data.title;
		this.message = this.data.message;
		this.cancel = this.data.cancel;
		this.confirm = this.data.confirm;
		this.confirmColor = this.data.confirm_color ? `btn-${this.data.confirm_color}` : "btn-light";
	}

	public ngOnInit(): void {	}
}

export interface IConfirmDialogData {
	title?: string;
	message: string;
	confirm: string;
	cancel: string;
	confirm_color?: string;
}

import { Component, OnInit } from "@angular/core";

import { environment } from "../environments/environment";
import { AuthService } from "./auth/services/auth.service";
import { ConfigService } from "./core/services/config.service";
import { LanguageService } from "./language/language.service";

@Component({
	selector: "uwcc-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	constructor(private config: ConfigService, private auth: AuthService, private lang: LanguageService) {}

	public ngOnInit(): void {
		this.auth.rehydrate();
		this.config.getSettings();
	}
}

import { LocalizeRouterService } from "localize-router";

/// <reference types="stripe-v3" />
import { Injectable } from "@angular/core";

import { STRIPE_ELEMENTS_OPTS, STRIPE_TOKEN_OPTS } from "./stripe.config";

@Injectable()
export class StripeService {
	private stripe: stripe.Stripe;
	private elements: stripe.elements.Elements;

	constructor(private localize: LocalizeRouterService) {}

	public static key(
		keys: uwcc_api.IPaymentSettings,
		isTest: boolean = false,
	): string {
		return isTest ? keys.test_publishable_key : keys.live_publishable_key;
	}

	public stripeInit(key: string): void {
		this.stripe = Stripe(key);
		this.elements = this.stripe.elements({
			locale: this.localize.parser.currentLang,
		});
	}

	public createElement(
		type: stripe.elements.elementsType,
		opts: stripe.elements.ElementsOptions = STRIPE_ELEMENTS_OPTS,
	): stripe.elements.Element {
		return this.elements.create(type, opts);
	}

	public createToken(
		element: stripe.elements.Element,
		opts: stripe.TokenOptions = STRIPE_TOKEN_OPTS,
	): Promise<stripe.TokenResponse> {
		return this.stripe
			.createToken(element, opts)
			.then(token => this.handleSuccess(token))
			.catch(reason => this.handleError(reason));
	}

	public paymentRequest(
		options: stripe.paymentRequest.StripePaymentRequestOptions,
	): stripe.paymentRequest.StripePaymentRequest {
		return this.stripe.paymentRequest(options);
	}

	private handleError(reason: any): any {
		return reason;
	}

	private handleSuccess(token: stripe.TokenResponse): stripe.TokenResponse {
		return token;
	}
}

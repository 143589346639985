import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

import { User } from "../../../core/models/user";
import { EDIT_USER_FORM_OPTIONS } from "./edit-user-form.options";

@Component({
	selector: "uwcc-edit-user-dialog",
	templateUrl: "./edit-user-dialog.component.html",
	styleUrls: ["./edit-user-dialog.component.scss"],
})
export class EditUserDialogComponent implements OnInit {
	public form: FormGroup;

	public roles: uwcc.ILabelValue[];

	constructor(public dialogRef: MatDialogRef<EditUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: User) {
		this.roles = EDIT_USER_FORM_OPTIONS.types;
	}

	public ngOnInit(): void {
		this.initForm();
	}

	public onSave(): void {
		Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsTouched());

		if (!this.form.valid) {
			return;
		}

		this.dialogRef.close(this.form.value);
	}

	private initForm(): void {
		this.form = new FormGroup({
			email: new FormControl(this.data.email, Validators.required),
			type: new FormControl(this.data.type, Validators.required),
			first_name: new FormControl(this.data.first_name, Validators.required),
			last_name: new FormControl(this.data.last_name, Validators.required),
		});
	}
}

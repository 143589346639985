import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatIconModule,
	MatListModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatSnackBarModule,
} from "@angular/material";

export const MATERIAL_COMPONENTS = [
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatIconModule,
	MatListModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatSnackBarModule,
];

@NgModule({
	imports: [CommonModule, MATERIAL_COMPONENTS],
	exports: [MATERIAL_COMPONENTS],
	declarations: [],
})
export class MaterialModule {}

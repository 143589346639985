import { Action } from "@ngrx/store";

import { AuthUser } from "../models/auth-user";

export const LOGIN_SUCCESS = "[Auth] Login Success";

export class LoginSuccess implements Action {
	readonly type = LOGIN_SUCCESS;
	constructor(public payload: { authUser: AuthUser; remember: boolean }) {}
}

import { Routes } from "@angular/router";

import { AuthGuard } from "./auth/guards/auth.guard";
import { RoleGuard } from "./auth/guards/role.guard";
import { User } from "./core/models/user";
import { UwccComponent } from "./uwcc/uwcc.component";

export const APP_ROUTING: Routes = [
	{
		path: "donate",
		loadChildren: "./donate/donate.module#DonateModule",
	},
	{
		path: "administrator",
		loadChildren: "./admin/admin.module#AdminModule",
		canActivate: [AuthGuard, RoleGuard],
		data: { roles: User.AdminRoles },
	},
	{
		path: "",
		redirectTo: "login",
		pathMatch: "full",
	},
	{
		path: "**",
		component: UwccComponent,
	},
];

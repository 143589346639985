import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/Rx";
import { AuthUser } from "../../auth/models/auth-user";
import { AppSettings } from "../models/app-settings";
import { Office } from "../models/office";
import { ApiService } from "./api.service";

@Injectable()
export class DataService {
	constructor(private api: ApiService) {}

	public login(payload: uwcc.IAuthenticatePayload): Observable<AuthUser> {
		return this.api.login(payload.body).pipe(
			map((res) => {
				return new AuthUser(res.data.token, res.data.user, res.data.roles);
			}),
		);
	}

	public getCountries(): Observable<uwcc_api.ICountry[]> {
		return this.api.getCountries().pipe(map((res) => res.data));
	}

	public getProvinces(countryId: string): Observable<uwcc_api.IProvince[]> {
		return this.api.getProvinces(countryId).pipe(map((res) => res.data.states));
	}

	public getOffice(code: string, isTest: boolean = false): Observable<Office> {
		return this.api.getOffice(code, isTest).pipe(map((res) => new Office(res.data.offices[0])));
	}

	public getAppSettings(): Observable<AppSettings> {
		return this.api.getAppSettings().pipe(map((res) => new AppSettings(res.data.settings)));
	}

	public postDonation(body: uwcc_api.IDonationBody, isTest: boolean = false): Promise<uwcc_api.IBaseResponse> {
		return this.api.postDonation(body, isTest).toPromise();
	}

	public getOfficeCodes(): Observable<uwcc_api.IOfficeAttributionCodesResponse> {
		return this.api.getOfficeCodes();
	}
}

import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModuleConfig } from "@ngx-translate/core";

import { TranslateHttpLoaderFactory } from "./translate-http-loader.factory";

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
	loader: {
		provide: TranslateLoader,
		useFactory: TranslateHttpLoaderFactory,
		deps: [HttpClient],
	},
};

import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "uwcc-errors",
	templateUrl: "./errors.component.html",
	styleUrls: ["./errors.component.scss"],
})
export class ErrorsComponent {
	@Input() errors: string[];

	constructor() {}
}

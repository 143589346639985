import { InjectionToken } from "@angular/core";
import { environment } from "../../environments/environment";

export const APP_CONFIG = new InjectionToken<uwcc.IAppConfig>("app.config");

export const UWCC_CONFIG: uwcc.IAppConfig = {
	system: {
		url: {
			en: environment.app.url.en,
			fr: environment.app.url.fr,
		},
		api: {
			url: environment.apiUrl,
		},
		ga: environment.app.ga,
		ga4: environment.app.ga4,
		i18n: {
			lang: environment.lang,
			source: "./i18n/",
			config: "./i18n/locales.json",
			// format: `.json`,
			format: `.json?${new Date().getTime()}`,
		},
		storage: {
			_prefix: "uwcc_",
			token: "token",
			user: "user",
		},
	},
};

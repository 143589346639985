import { AfterContentInit, Component, ContentChildren, EventEmitter, OnInit, Output, QueryList } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

import { WizardStepComponent } from "../wizard-step/wizard-step.component";

@Component({
	selector: "uwcc-wizard",
	templateUrl: "./wizard.component.html",
	styleUrls: ["./wizard.component.scss"],
})
export class WizardComponent implements OnInit, AfterContentInit {
	@ContentChildren(WizardStepComponent) private wSteps: QueryList<WizardStepComponent>;
	@Output() private stepChanged: EventEmitter<WizardStepComponent>;

	public isLoading: boolean;

	public get hasNextStep(): boolean {
		return this.activeStepIndex < this.steps.length - 1;
	}

	public get hasPrevStep(): boolean {
		return this.activeStepIndex > 0;
	}

	public steps: WizardStepComponent[];

	public get activeStep(): WizardStepComponent {
		return this.steps.find((step) => step.isActive);
	}

	public set activeStep(step: WizardStepComponent) {
		if (step !== this.activeStep) {
			this.activeStep.isActive = false;
			step.isActive = true;
			this.stepChanged.emit(step);
		}
	}

	public get labelPrev(): string {
		return this.activeStep.labelPrev ? this.activeStep.labelPrev : "donate.actions.prev";
	}

	public get labelNext(): string {
		return this.activeStep.labelNext ? this.activeStep.labelNext : "donate.actions.next";
	}

	public get canPrev(): boolean {
		return this.activeStep.showPrev;
	}

	public get canNext(): boolean {
		return this.activeStep.showNext;
	}

	public get showControls(): boolean {
		return this.activeStep.showControls;
	}

	public get activeStepIndex(): number {
		return this.steps.indexOf(this.activeStep);
	}

	constructor() {
		this.stepChanged = new EventEmitter();
		this.steps = [];
		this.isLoading = false;
	}

	public ngOnInit(): void {}

	public ngAfterContentInit(): void {
		this.wSteps.forEach((step) => this.steps.push(step));
		this.steps[0].isActive = true;
	}

	public next(): void {
		if (this.hasNextStep) {
			this.isLoading = true;
			this.activeStep.onNext.emit();
		}
	}

	public nextSuccess(): void {
		const nextStep: WizardStepComponent = this.steps[this.activeStepIndex + 1];
		this.activeStep = nextStep;
		this.isLoading = false;
	}

	public nextFail(): void {
		this.isLoading = false;
	}

	public previous(): void {
		if (this.hasPrevStep) {
			this.isLoading = true;
			this.activeStep.onPrev.emit();
		}
	}

	public previousSuccess(): void {
		const prevStep: WizardStepComponent = this.steps[this.activeStepIndex - 1];
		this.activeStep = prevStep;
		this.isLoading = false;
	}

	public previousFail(): void {
		this.isLoading = false;
	}

	public complete(): void {
		this.activeStep.onComplete.emit();
	}
}

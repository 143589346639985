import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { LocalizeRouterService } from "localize-router";
import { of } from "rxjs";
import { catchError, exhaustMap, map, switchMap, tap } from "rxjs/operators";
import { StorageService } from "../../core/services/storage.service";
import * as Auth from "../actions";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthEffects {
	@Effect()
	login$ = this.actions$.pipe(
		ofType(Auth.LOGIN),
		map((action: Auth.Login) => action.payload),
		exhaustMap(auth =>
			this.authService.authenticate(auth).pipe(
				map(authUser => new Auth.LoginSuccess({ authUser, remember: auth.body.remember })),
				catchError(error => of(new Auth.LoginFailure(error))),
			),
		),
	);

	@Effect({ dispatch: false })
	loginSuccess$ = this.actions$.pipe(
		ofType(Auth.LOGIN_SUCCESS, Auth.REFRESH_LOGIN_SUCCESS),
		map((action: Auth.LoginSuccess | Auth.RefreshLoginSuccess) => action.payload),
		tap(payload => {
			this.storageService.token = payload.remember ? payload.authUser.token : null;
			this.storageService.user = payload.remember ? payload.authUser.user.id : null;
		}),
	);

	@Effect()
	refreshLogin$ = this.actions$.pipe(
		ofType(Auth.REFRESH_LOGIN),
		map((action: Auth.RefreshLogin) => action.payload),
		exhaustMap(auth =>
			this.authService.authenticate(auth).pipe(
				map(authUser => new Auth.RefreshLoginSuccess({ authUser, remember: auth.body.remember })),
				catchError(error => of(new Auth.RefreshLoginFailure(error))),
			),
		),
	);

	@Effect({ dispatch: false })
	refreshLoginFailure$ = this.actions$.pipe(
		ofType(Auth.REFRESH_LOGIN_FAILURE),
		map((action: Auth.RefreshLoginFailure) => action.payload),
		tap(payload => {
			this.authService.showLoginDialog();
			this.snackBar.open(payload.error.message, null, { duration: 2000 });
		}),
	);

	@Effect({ dispatch: false })
	loginRedirect$ = this.actions$.pipe(
		ofType(Auth.LOGIN_REDIRECT, Auth.LOGOUT, Auth.GET_USER_FAILURE),
		tap(authed => {
			this.router.navigate([this.localizeRouterService.translateRoute("/login")]);
		}),
	);

	@Effect({ dispatch: false })
	logout$ = this.actions$.pipe(
		ofType(Auth.LOGOUT, Auth.GET_USER_FAILURE),
		tap(() => this.storageService.clear()),
	);

	@Effect({ dispatch: false })
	loginRedirectTo$ = this.actions$.pipe(
		ofType(Auth.LOGIN_REDIRECT_TO),
		map((action: Auth.LoginRedirectTo) => action.payload),
		tap(rootUrl => {
			this.router.navigate([this.localizeRouterService.translateRoute(rootUrl)]);
		}),
	);

	@Effect()
	getUser$ = this.actions$.pipe(
		ofType(Auth.GET_USER),
		map((action: Auth.GetUser) => action.payload),
		switchMap(payload =>
			this.authService.fetchUserDetails(payload).pipe(
				map(userDetails => new Auth.GetUserSuccess(userDetails)),
				catchError(error => of(new Auth.GetUserFailure(error))),
			),
		),
	);

	@Effect({ dispatch: false })
	getUserFailure$ = this.actions$.pipe(
		ofType(Auth.GET_USER_FAILURE),
		map((action: Auth.GetUserFailure) => action.payload),
		tap(payload => {
			this.snackBar.open(payload.error.message, null, { duration: 2000 });
		}),
	);

	constructor(
		private actions$: Actions,
		private router: Router,
		private authService: AuthService,
		private storageService: StorageService,
		private localizeRouterService: LocalizeRouterService,
		private snackBar: MatSnackBar,
	) {}
}

import { GetUserFailure } from "./get-user-failure.action";
import { GetUserSuccess } from "./get-user-success.action";
import { GetUser } from "./get-user.action";
import { LoginFailure } from "./login-failure.action";
import { LoginRedirectTo } from "./login-redirect-to.action";
import { LoginRedirect } from "./login-redirect.action";
import { LoginSuccess } from "./login-success.action";
import { Login } from "./login.action";
import { Logout } from "./logout.action";
import { RefreshLoginFailure } from "./refresh-login-failure.action";
import { RefreshLoginSuccess } from "./refresh-login-success.action";
import { RefreshLogin } from "./refresh-login.action";
import { SetToken } from "./set-token.action";

export type Actions =
	| Login
	| LoginSuccess
	| LoginFailure
	| LoginRedirect
	| LoginRedirectTo
	| Logout
	| SetToken
	| GetUser
	| GetUserFailure
	| GetUserSuccess
	| RefreshLogin
	| RefreshLoginFailure
	| RefreshLoginSuccess;

export * from "./login.action";
export * from "./login-failure.action";
export * from "./login-success.action";
export * from "./login-redirect.action";
export * from "./login-redirect-to.action";
export * from "./logout.action";
export * from "./set-token.action";
export * from "./get-user-failure.action";
export * from "./get-user-success.action";
export * from "./get-user.action";
export * from "./refresh-login-failure.action";
export * from "./refresh-login-success.action";
export * from "./refresh-login.action";

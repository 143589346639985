/// <reference types="google.analytics" />
import { Injectable } from "@angular/core";

@Injectable()
export class GoogleAnalyticsService {
	private uaProperties: string[];
	private gtagProperties: string[];

	constructor() {
		this.uaProperties = [];
		this.gtagProperties = [];
	}

	public createUA(
		trackingId: string,
		cookieDomain: string,
		name: string = "global",
		fieldsObject?: UniversalAnalytics.FieldsObject,
	): void {
		ga("create", trackingId, cookieDomain, name, fieldsObject);

		if (!this.uaProperties.includes(name)) {
			this.uaProperties.push(name);
		}
	}

	public createGtag(id: string) {
		gtag("config", id);

		if (!this.gtagProperties.includes(id)) {
			this.gtagProperties.push(id);
		}
	}

	public emitPageView(location: string = ""): void {
		const path = `${window.location.pathname}/${location}`;

		this.uaProperties.forEach((prop) => {
			ga(`${prop}.send`, "pageview", path);
		});

		gtag("event", "page_view", { page_location: path });
	}

	public uaEmitEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
		this.uaProperties.forEach((prop) => {
			ga(`${prop}.send`, "event", {
				eventCategory,
				eventLabel,
				eventAction,
				eventValue,
			});
		});
	}

	public gtagDonateEvent(value: number, recurrence: string): void {
		if (!this.gtagProperties.length) {
			return;
		}

		gtag("event", "Donate", {
			value,
			recurrence,
		});
	}

	public gtagOutboundLinkEvent(eventLabel: string): void {
		if (!this.gtagProperties.length) {
			return;
		}

		gtag("event", "Outbound Link Click", { event_label: eventLabel });
	}
}

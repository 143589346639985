import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "uwcc-error",
	templateUrl: "./error.component.html",
	styleUrls: ["./error.component.scss"],
})
export class ErrorComponent {
	@Input() error: string;

	constructor() {}
}

import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { LocalizeRouterModule } from "localize-router";

import { LoginFormComponent } from "../auth/login-form/login-form.component";
import { SharedModule } from "../shared/shared.module";
import { AUTH_ROUTING } from "./auth.routing";
import { LoginDialogComponent } from "./components/dialogs/login-dialog/login-dialog.component";
import { AuthEffects } from "./effects/auth.effects";
import { AuthGuard } from "./guards/auth.guard";
import { RoleGuard } from "./guards/role.guard";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { reducers } from "./reducers";
import { FEATURE } from "./reducers/index";
import { AuthService } from "./services/auth.service";

export const AUTH_COMPONENTS = [LoginComponent, LoginFormComponent, LogoutComponent, LoginDialogComponent];
export const AUTH_SERVICES = [AuthService];
export const AUTH_GUARDS = [AuthGuard, RoleGuard];

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, SharedModule],
	declarations: [AUTH_COMPONENTS],
	exports: [AUTH_COMPONENTS],
	entryComponents: [LoginDialogComponent],
})
export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: RootAuthModule,
			providers: [
				AUTH_SERVICES,
				AUTH_GUARDS,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: TokenInterceptor,
					multi: true,
				},
			],
		};
	}
}

@NgModule({
	imports: [
		AuthModule,
		RouterModule.forChild(AUTH_ROUTING),
		LocalizeRouterModule.forChild(AUTH_ROUTING),
		StoreModule.forFeature(FEATURE, reducers),
		EffectsModule.forFeature([AuthEffects]),
	],
})
export class RootAuthModule {}

import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as Settings from "../actions";
import { DataService } from "../services/data.service";

@Injectable()
export class SettingsEffects {
	constructor(private actions$: Actions, private dataService: DataService) {}

	@Effect()
	getSettings$ = this.actions$.pipe(
		ofType(Settings.GET_SETTINGS),
		switchMap(() =>
			this.dataService.getAppSettings().pipe(
				map(settings => new Settings.GetSettingsSuccess(settings)),
				catchError(error => of(new Settings.GetSettingsFailure(error))),
			),
		),
	);
}

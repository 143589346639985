import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { LocalizeRouterSettings } from "localize-router";
import { LocalizeRouterHttpLoader } from "localize-router-http-loader";
import { UWCC_CONFIG } from "../config/uwcc.config";

export function LocalizeRouterHttpLoaderFactory(
	translate: TranslateService,
	location: Location,
	settings: LocalizeRouterSettings,
	http: HttpClient,
	path?: string,
): LocalizeRouterHttpLoader {
	return new LocalizeRouterHttpLoader(translate, location, settings, http, UWCC_CONFIG.system.i18n.config);
}

import { LocalizeRouterService } from "localize-router";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ReceiptImageType } from "../../admin/services/image.service";
import { ConfigService } from "./config.service";

export const ReqMethod = {
	Get: "GET",
	Post: "POST",
	Put: "PUT",
	Delete: "DELETE",
	Patch: "PATCH",
};

@Injectable()
export class ApiService {
	private url: string;

	constructor(private config: ConfigService, private http: HttpClient, private localize: LocalizeRouterService) {
		this.url = config.app.system.api.url;
	}

	//#region Utils
	public getCountries(): Observable<uwcc_api.ICountriesResponse> {
		return this.get<uwcc_api.ICountriesResponse>(`/countries`);
	}

	public getAppSettings(): Observable<uwcc_api.IAppSettingsResponse> {
		return this.get<uwcc_api.IAppSettingsResponse>(`/settings`);
	}
	//#endregion

	//#region Donation
	public getProvinces(countryId: string): Observable<uwcc_api.IProvincesResponse> {
		return this.get<uwcc_api.IProvincesResponse>(`/states/${countryId}`);
	}

	public getOffice(code: string, isTest: boolean = false): Observable<uwcc_api.IOfficesResponse> {
		const filters = isTest ? { test: true } : null;
		return this.get<uwcc_api.IOfficesResponse>(`/offices/${code}`, filters);
	}

	public postDonation(body: uwcc_api.IDonationBody, isTest: boolean = false): Observable<uwcc_api.IBaseResponse> {
		const filters = isTest ? { test: true } : null;
		return this.do<uwcc_api.IBaseResponse>(ReqMethod.Post, `/donations`, body, filters);
	}

	public getOfficeCodes(): Observable<uwcc_api.IOfficeAttributionCodesResponse> {
		return this.get("/office-codes");
	}
	//#endregion

	//#region User
	public login(body: uwcc_api.ILoginTokenBody): Observable<uwcc_api.ILoginTokenResponse> {
		return this.do<uwcc_api.ILoginTokenResponse>(ReqMethod.Post, `/auth/login`, body);
	}

	public getUserDetails(userId: string): Observable<uwcc_api.IUserDetailsResponse> {
		return this.get<uwcc_api.IUserDetailsResponse>(`/users/${userId}`);
	}
	//#endregion

	//#region Offices Admin
	public createAdminOffce(body: uwcc_api.IPostAdminOfficeBody): Observable<uwcc_api.IAdminOfficesResponse> {
		return this.do(ReqMethod.Post, `/admin/offices`, body);
	}

	public getAdminOffices(): Observable<uwcc_api.IAdminOfficesResponse> {
		return this.get<uwcc_api.IAdminOfficesResponse>(`/admin/offices`);
	}

	public getAdminOffice(code: string): Observable<uwcc_api.IAdminOfficesResponse> {
		return this.get<uwcc_api.IAdminOfficesResponse>(`/admin/offices/${code}`);
	}

	public getAdminOfficeDashboard(code: string): Observable<uwcc_api.IAdminOfficeDashboardResponse> {
		return this.get<uwcc_api.IAdminOfficeDashboardResponse>(`/admin/offices/${code}/dashboard`);
	}

	public getAdminOfficeFunds(code: string): Observable<uwcc_api.IAdminOfficeFundsResponse> {
		return this.get<uwcc_api.IAdminOfficeFundsResponse>(`/admin/offices/${code}/funds`);
	}

	public patchAdminOfficeFund(body: uwcc_api.IOfficeFundCreateBody): Observable<uwcc_api.IAdminOfficeFundResponse> {
		return this.do<uwcc_api.IAdminOfficeFundResponse>(ReqMethod.Patch, `/admin/funds/${body.id}`, body);
	}

	public postAdminOfficeFund(
		code: string,
		body: uwcc_api.IOfficeFundUpdateBody,
	): Observable<uwcc_api.IAdminOfficeFundResponse> {
		return this.do<uwcc_api.IAdminOfficeFundResponse>(ReqMethod.Put, `/admin/offices/${code}/funds`, body);
	}

	public saveAdminOffice(
		code: string,
		body: uwcc_api.ISaveOfficeDetailsBody,
	): Observable<uwcc_api.IAdminOfficesResponse> {
		return this.do(ReqMethod.Put, `/admin/offices/${code}`, body);
	}

	public sendOfficeEmailSample(code: string, lang: string): Observable<uwcc_api.IBaseResponse> {
		return this.get(`/admin/offices/${code}/email`, null, lang);
	}

	public getSampleReceipt(code: string): Observable<HttpResponse<Blob>> {
		return this.getBlob(`/admin/offices/${code}/receipt/sample`);
	}
	//#endregion

	//#region Members Admin
	public deleteAdminOfficeMember(code: string, id: string): Observable<uwcc_api.IAdminOfficeMembersResponse> {
		return this.do(ReqMethod.Delete, `/admin/offices/${code}/members/${id}`);
	}

	public getAdminOfficeMembers(code: string): Observable<uwcc_api.IAdminOfficeMembersResponse> {
		return this.get<uwcc_api.IAdminOfficeMembersResponse>(`/admin/offices/${code}/members`);
	}

	public postOfficeMember(
		code: string,
		body: uwcc_api.IPostOfficeMemberBody,
	): Observable<uwcc_api.IAdminOfficeMembersResponse> {
		return this.do(ReqMethod.Post, `/admin/offices/${code}/members`, body);
	}
	//#endregion

	//#region Images
	public getReceiptImage(code: string, type: ReceiptImageType): Observable<HttpResponse<Blob>> {
		return this.getBlob(`/admin/offices/${code}/receipt/${type}`);
	}

	public uploadImage(code: string, body: uwcc_api.IImageUploadBody): Observable<uwcc_api.IAdminOfficesResponse> {
		return this.do(ReqMethod.Put, `/admin/offices/${code}/images`, body);
	}

	public deleteImage(code: string, body: uwcc_api.IImageDeleteBody): Observable<uwcc_api.IAdminOfficesResponse> {
		return this.do(ReqMethod.Delete, `/admin/offices/${code}/images`, body);
	}
	//#endregion

	//#region Donations Admin
	public getDonations(code: string, filters?: uwcc.IGetDonationFilters): Observable<uwcc_api.IDonationsResponse> {
		return this.get<uwcc_api.IDonationsResponse>(`/admin/offices/${code}/donations`, filters);
	}

	public getDonation(code: string): Observable<uwcc_api.IDonationsResponse> {
		return this.get<uwcc_api.IDonationsResponse>(`/admin/donations/${code}`);
	}

	public deleteDonation(code: string): Observable<uwcc_api.IDonationsResponse> {
		return this.do<uwcc_api.IDonationsResponse>(ReqMethod.Delete, `/admin/donations/${code}`);
	}

	public patchDonation(code: string, email: string): Observable<uwcc_api.IDonationsResponse> {
		return this.do<uwcc_api.IDonationsResponse>(ReqMethod.Patch, `/admin/donations/${code}`, { email });
	}

	public downloadDonations(code: string, filters?: uwcc.IGetDonationFilters): Observable<HttpResponse<Blob>> {
		const f: uwcc.IGetDonationFilters = { ...filters, export: true };

		return this.getBlob(`/admin/offices/${code}/donations`, f);
	}

	public resendDonationReceipt(code: string): Observable<uwcc_api.IBaseResponse> {
		return this.get<uwcc_api.IBaseResponse>(`/admin/donations/${code}/receipt/resend`);
	}

	public downloadDonationReceipt(code: string): Observable<HttpResponse<Blob>> {
		return this.getBlob(`/admin/donations/${code}/receipt`);
	}
	//#endregion

	//#region Subscriptions Admin
	public getSubscriptions(
		code: string,
		filters?: uwcc.IGetSubscriptionsFilters,
	): Observable<uwcc_api.ISubscriptionsResponse> {
		return this.get<uwcc_api.ISubscriptionsResponse>(`/admin/offices/${code}/subscriptions`, filters);
	}

	public getSubscription(id: string): Observable<uwcc_api.ISubscriptionsResponse> {
		return this.get<uwcc_api.ISubscriptionsResponse>(`/admin/subscriptions/${id}`);
	}

	public deleteSubscription({
		id,
		send_receipt,
	}: {
		id: string;
		send_receipt: boolean;
	}): Observable<uwcc_api.ISubscriptionsResponse> {
		return this.do<uwcc_api.ISubscriptionsResponse>(ReqMethod.Delete, `/admin/subscriptions/${id}`, null, {
			send_receipt,
		});
	}

	public updateSubscription(
		id: string,
		body: uwcc_api.ISubscriptionUpdateBody,
	): Observable<uwcc_api.ISubscriptionsResponse> {
		return this.do<uwcc_api.ISubscriptionsResponse>(ReqMethod.Put, `/admin/subscriptions/${id}`, body);
	}

	public downloadSubscriptionReceipt(subscription_id: string, receipt_id: string): Observable<HttpResponse<Blob>> {
		return this.getBlob(`/admin/subscriptions/${subscription_id}/receipt/${receipt_id}`);
	}
	//#endregion

	private do<T>(requestMethod: string, resource: string, body?: object, params?: object): Observable<T> {
		const httpParams: HttpParams = this.assembleHttpParams(params);

		return this.http
			.request(requestMethod, `${this.url}${resource}`, { body, headers: this.getHeaders(), params: httpParams })
			.pipe(
				catchError((error) => this.handleError(error)),
				map((success) => this.handleSuccess<T>(success)),
			);
	}

	private get<T>(resource: string, filters?: object, lang?: string): Observable<T> {
		const httpParams: HttpParams = this.assembleHttpParams(filters);

		return this.http
			.request(ReqMethod.Get, `${this.url}${resource}`, {
				headers: this.getHeaders(lang),
				params: httpParams,
			})
			.pipe(
				catchError((error) => this.handleError(error)),
				map((success) => this.handleSuccess<T>(success)),
			);
	}

	private getBlob(resource: string, filters?): Observable<HttpResponse<Blob>> {
		const httpParams: HttpParams = this.assembleHttpParams(filters);

		return this.http.request(ReqMethod.Get, `${this.url}${resource}`, {
			headers: this.getHeaders(),
			responseType: "blob",
			params: httpParams,
			observe: "response",
		});
	}

	private getHeaders(lang?: string): HttpHeaders {
		return new HttpHeaders({
			"accept-language": lang ? lang : this.localize.parser.currentLang,
		});
	}

	private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
		return observableThrowError(error);
	}

	private handleSuccess<T>(response): T {
		return response;
	}

	private assembleHttpParams(filters?): HttpParams {
		return filters
			? Object.entries(filters)
					.filter(([key, value]) => value) // Filter out params without values
					.reduce((params, [key, value]) => params.set(key, value as string), new HttpParams())
			: null;
	}
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs/Rx";
import { componentDestroyed } from "../../shared/component-destroyed";
import * as Auth from "../actions";
import * as fromAuth from "../reducers";
import { AuthService } from "../services/auth.service";

@Component({
	selector: "uwcc-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
	public pending$: Observable<boolean>;
	public errors$: Observable<string[]>;

	// TODO: Replace store with Auth service
	constructor(private store: Store<fromAuth.IAuthState>, private auth: AuthService) {
		this.pending$ = this.store.select(fromAuth.getLoginPending);
		this.errors$ = this.store.select(fromAuth.getLoginError);
	}

	public ngOnInit(): void {
		this.auth.loggedIn$.pipe(takeUntil(componentDestroyed(this))).subscribe(loggedIn => this.auth.loginRedirectTo());
	}

	public ngOnDestroy(): void {}

	public onLogin($event: uwcc_api.ILoginTokenBody): void {
		this.store.dispatch(new Auth.Login({ body: $event }));
	}
}

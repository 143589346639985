import * as fromRouter from "@ngrx/router-store";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { storeFreeze } from "ngrx-store-freeze";

import { environment } from "../../environments/environment";
import { IRouterStateUrl } from "../shared/custom-router-state-serializer";

export interface IState {
	routerReducer: fromRouter.RouterReducerState<IRouterStateUrl>;
}

export const reducers: ActionReducerMap<IState> = {
	routerReducer: fromRouter.routerReducer,
};

export const metaReducers: Array<MetaReducer<IState>> = !environment.production ? [logger, storeFreeze] : [];

// console.log all actions
export function logger(reducer: ActionReducer<IState>): ActionReducer<IState> {
	return (state: IState, action: any): IState => {
		console.groupCollapsed(`NGRX Logger / ${action.type}`);
		console.log("STATE", state);
		console.log("ACTION", action);
		console.groupEnd();

		return reducer(state, action);
	};
}

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "uwcc-uwcc",
	templateUrl: "./uwcc.component.html",
	styleUrls: ["./uwcc.component.scss"],
})
export class UwccComponent implements OnInit {
	constructor(private translate: TranslateService, private title: Title) {}

	ngOnInit() {
		this.translate.get("app.owner.name").subscribe(name => this.title.setTitle(name));
	}
}

import { takeUntil } from "rxjs/operators";

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";

import { DataService } from "../../core/services/data.service";
import { componentDestroyed } from "../component-destroyed";

@Component({
	selector: "uwcc-country-state-selector",
	templateUrl: "./country-state-selector.component.html",
	styleUrls: ["./country-state-selector.component.scss"],
})
export class CountryStateSelectorComponent implements OnInit, OnDestroy {
	@Input() public form: FormGroup;
	@Input() public isUSDonation: boolean;

	public countries: uwcc_api.ICountry[];
	public provinces: uwcc_api.IProvince[];

	constructor(private data: DataService, private cdRef: ChangeDetectorRef) {
		this.countries = [];
		this.provinces = [];
	}

	public ngOnInit(): void {
		this.provinceCtrl.disable();
		this.data
			.getCountries()
			.pipe(takeUntil(componentDestroyed(this)))
			.subscribe((countries) => this.updateCountries(countries));
		this.countryCtrl.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((id) => this.updateProvinces(id));
	}

	public ngOnDestroy(): void {}

	public get countryCtrl(): AbstractControl {
		return this.form.controls["country"];
	}

	public get provinceCtrl(): AbstractControl {
		return this.form.controls["state"];
	}

	public get cityCtrl(): AbstractControl {
		return this.form.controls["city"];
	}

	public get postalCodeCtrl(): AbstractControl {
		return this.form.controls["postal_code"];
	}

	private updateCountries(countries: uwcc_api.ICountry[]): void {
		this.countries = countries;
		this.cdRef.detectChanges();
	}

	private updateProvinces(countryId: string): void {
		this.provinces = [];
		this.provinceCtrl.disable();
		this.data
			.getProvinces(countryId)
			.pipe(takeUntil(componentDestroyed(this)))
			.subscribe((provinces) => {
				this.provinces = provinces;
				if (provinces.length > 0) {
					this.provinceCtrl.enable();
				}
				this.cdRef.detectChanges();
			});
	}
}

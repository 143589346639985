export class Office implements uwcc_api.IOffice {
	public code: string;
	public name: string;
	public office_details: uwcc_api.IOfficeDetails;
	public app_customization: uwcc_api.IAppCustomization;
	public payment_settings: uwcc_api.IPaymentSettings;
	public currency: "CAD" | "USD";
	public monthly_donations: boolean;
	public receipt_settings: uwcc_api.IReceiptSettings;
	public funds: uwcc_api.IFund[];

	constructor(data?: Office) {
		Object.assign(this, data);
	}
}

export const environment: uwcc.IEnvironment = {
	app: {
		recaptcha_key: "6LfKxgEVAAAAAHNjBHN7KWK6qe7w-unHN0QBX3BV",
		url: {
			en: "/",
			fr: "/fr/",
		},
		ga4: null,
		ga: null,
	},
	production: false,
	apiUrl: "https://app.dev.uwcc.marsworks.com/api",
	lang: "en",
};

import { User } from "../../../core/models/user";
export interface IEditUserFormOptions {
	types: uwcc.ILabelValue[];
}

export const EDIT_USER_FORM_OPTIONS = {
	types: [
		{
			label: "user.type.administrator",
			value: User.Roles.Administrator,
		},
		{
			label: "user.type.member",
			value: User.Roles.Member,
		},
		{
			label: "user.type.reporter",
			value: User.Roles.Reporter,
		},
	],
};

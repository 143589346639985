import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
	selector: "uwcc-login-form",
	templateUrl: "./login-form.component.html",
	styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent {
	@Input()
	public set pending(isPending: boolean) {
		if (isPending) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	@Input() public errors: string[];
	@Input() public email: string;
	@Input() public password: string;
	@Input() public remember: boolean;

	public form: FormGroup;
	public isIE: boolean;

	@Output() private login: EventEmitter<uwcc_api.ILoginTokenBody>;

	constructor(private fb: FormBuilder) {
		this.login = new EventEmitter();
		this.isIE = window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
		this.initForm();
	}

	public onSubmit(): void {
		if (this.form.valid) {
			this.login.emit(this.form.value);
		}
	}

	private initForm(): void {
		this.form = this.fb.group({
			email: this.fb.control(this.email, Validators.required),
			password: this.fb.control(this.password, Validators.required),
			remember: this.fb.control(this.remember),
		});
	}
}

import { Action } from "@ngrx/store";

import { Role } from "../../core/models/role";
import { User } from "../../core/models/user";

export const GET_USER_SUCCESS = "[Auth] Get User Success";

export class GetUserSuccess implements Action {
	public readonly type = GET_USER_SUCCESS;
	constructor(public payload: { user: User; roles: Role[] }) {}
}

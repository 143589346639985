import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "uwcc-wizard-step",
	templateUrl: "./wizard-step.component.html",
	styleUrls: ["./wizard-step.component.scss"],
})
export class WizardStepComponent {

	@Input() public title: string;
	@Input() public hidden = false;
	@Input() public isValid = true;
	@Input() public showNext = true;
	@Input() public showPrev = true;
	@Input() public showControls = true;
	@Input() public labelNext;
	@Input() public labelPrev;
	@Input() public errors: string[];

	@Output() public onNext: EventEmitter<any> = new EventEmitter<any>();
	@Output() public onPrev: EventEmitter<any> = new EventEmitter<any>();
	@Output() public onComplete: EventEmitter<any> = new EventEmitter<any>();


	@Input("isActive")
	public set isActive(isActive: boolean) {
		this._isActive = isActive;
		this.isDisabled = false;
	}

	public get isActive(): boolean {
		return this._isActive;
	}

	private _isActive = false;
	private isDisabled = true;

	constructor() {}
}

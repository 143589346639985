import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TextMaskModule } from "angular2-text-mask/dist/angular2TextMask";
import { CountryStateSelectorComponent } from "./country-state-selector/country-state-selector.component";
import { AddUserDialogComponent } from "./dialogs/add-user-dialog/add-user-dialog.component";
import { ConfirmDialogComponent } from "./dialogs/confirm-dialog/confirm-dialog.component";
import { EditUserDialogComponent } from "./dialogs/edit-user-dialog/edit-user-dialog.component";
import { ErrorComponent } from "./error/error.component";
import { ErrorsComponent } from "./errors/errors.component";
import { MaterialModule } from "./material/material.module";
import { PaginatorComponent } from "./paginator/paginator.component";
import { FancyBooleanPipe } from "./pipes/fancy-boolean.pipe";
import { NewlineToBrPipe } from "./pipes/newline-to-br.pipe";
import { ResultsTotalComponent } from "./results-total/results-total.component";
import { ScriptInjectComponent } from "./script-inject/script-inject.component";
import { StripeService } from "./stripe/stripe.service";
import { WizardStepComponent } from "./wizard-step/wizard-step.component";
import { WizardComponent } from "./wizard/wizard.component";

const IMPORT_EXPORT = [TranslateModule, MaterialModule, TextMaskModule];

const PIPES = [NewlineToBrPipe, FancyBooleanPipe];

const DECLARE_EXPORT = [
	AddUserDialogComponent,
	ConfirmDialogComponent,
	CountryStateSelectorComponent,
	EditUserDialogComponent,
	ErrorsComponent,
	PaginatorComponent,
	ResultsTotalComponent,
	ScriptInjectComponent,
	WizardComponent,
	WizardStepComponent,
	PIPES,
];

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, IMPORT_EXPORT],
	exports: [IMPORT_EXPORT, DECLARE_EXPORT],
	declarations: [DECLARE_EXPORT, ErrorComponent],
	entryComponents: [ConfirmDialogComponent, AddUserDialogComponent, EditUserDialogComponent],
	providers: [StripeService],
})
export class SharedModule {}

import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs/Rx";

import { APP_CONFIG } from "../../config/uwcc.config";
import * as Settings from "../actions/settings";
import { AppSettings } from "../models/app-settings";
import * as fromCore from "../reducers";

@Injectable()
export class ConfigService {

	public urls$: Observable<uwcc_api.ITranslation>;

	constructor(@Inject(APP_CONFIG) public app: uwcc.IAppConfig, private store: Store<fromCore.ICoreState>) {
		this.urls$ = this.store.select(fromCore.getUrls);
	}

	public getSettings(): void {
		this.store.dispatch(new Settings.GetSettings());
	}
}

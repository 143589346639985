import { Action } from "@ngrx/store";

import { AuthUser } from "../models/auth-user";

export const REFRESH_LOGIN_SUCCESS = "[Auth] Refresh Login Success";

export class RefreshLoginSuccess implements Action {
	readonly type = REFRESH_LOGIN_SUCCESS;
	constructor(public payload: { authUser: AuthUser; remember: boolean }) {}
}

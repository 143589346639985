import { Role } from "../../core/models/role";
import { User } from "../../core/models/user";
import * as auth from "../actions";

export interface IState {
	loggedIn: boolean;
	user: User | null;
	token: string | null;
	roles: Role[] | null;
	root: string | null;
}

export const initialState: IState = {
	loggedIn: false,
	user: null,
	token: null,
	roles: null,
	root: null,
};

export function reducer(state = initialState, action: auth.Actions): IState {
	switch (action.type) {
		case auth.LOGIN_SUCCESS:
		case auth.REFRESH_LOGIN_SUCCESS: {
			return {
				...state,
				loggedIn: true,
				user: action.payload.authUser.user,
				token: action.payload.authUser.token,
				roles: action.payload.authUser.roles,
			};
		}

		case auth.LOGIN_REDIRECT: {
			return {
				...state,
				root: action.payload,
			};
		}

		case auth.LOGOUT: {
			return initialState;
		}

		case auth.SET_TOKEN: {
			return {
				...state,
				token: action.payload,
				loggedIn: true,
			};
		}

		case auth.GET_USER_SUCCESS: {
			return {
				...state,
				roles: action.payload.roles,
				user: action.payload.user,
			};
		}

		default: {
			return state;
		}
	}
}

export const getLoggedIn = (state: IState) => state.loggedIn;
export const getUser = (state: IState) => state.user;
export const getRoles = (state: IState) => state.roles;
export const getRoot = (state: IState) => state.root;

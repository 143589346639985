
import {map} from 'rxjs/operators';
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import * as Auth from "../actions";
import * as fromAuth from "../reducers";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private auth: AuthService) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.auth.loggedIn$.pipe(
			map(loggedIn => {
				if (!loggedIn) {
					this.auth.loginRedirect(state.url);
					return false;
				}

				return true;
			}));
	}
}

export class User implements uwcc_api.IUser {

	public static readonly Roles = {
		Administrator: "administrator",
		Member: "member",
		Reporter: "reporter",
		Office: "office",
	};

	public static readonly AdminRoles = [
		User.Roles.Administrator,
		User.Roles.Office,
		User.Roles.Reporter,
		User.Roles.Member,
	];

	public id: string;
	public first_name: string;
	public last_name: string;
	public email: string;
	public created_at: string;
	public type: string;

	constructor(_data?: uwcc_api.IUser) {
		Object.assign(this, _data);
	}
}

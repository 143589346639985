import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { LocalizeParser, LocalizeRouterConfig, LocalizeRouterSettings } from "localize-router";

import { LocalizeRouterHttpLoaderFactory } from "./localize-http-loader.factory";

export const LOCALIZE_ROUTER_MODULE_CONFIG: LocalizeRouterConfig = {
	alwaysSetPrefix: false,
	parser: {
		provide: LocalizeParser,
		useFactory: LocalizeRouterHttpLoaderFactory,
		deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
	},
};

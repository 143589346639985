import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
	selector: "uwcc-script-inject",
	templateUrl: "./script-inject.component.html",
	styleUrls: ["./script-inject.component.scss"],
})
export class ScriptInjectComponent implements AfterViewInit {
	@Input() public src: string;
	@Input() public type: string;
	@Input() public async: boolean;

	@ViewChild("script") private script: ElementRef;

	public ngAfterViewInit(): void {
		this.convertToScript();
	}

	private convertToScript() {
		const element = this.script.nativeElement;
		const script = document.createElement("script");
		script.type = this.type ? this.type : "text/javascript";

		if (this.src) {
			script.src = this.src;
		}

		if (this.async) {
			script.async = this.async;
		}

		if (element.innerHTML) {
			script.innerHTML = element.innerHTML;
		}

		const parent = element.parentElement;
		parent.parentElement.replaceChild(script, parent);
	}
}

import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LocalizeRouterSettings } from "localize-router";
import { LocalizeRouterHttpLoader } from "localize-router-http-loader";

import { UWCC_CONFIG } from "../config/uwcc.config";

export function TranslateHttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, UWCC_CONFIG.system.i18n.source, UWCC_CONFIG.system.i18n.format);
}

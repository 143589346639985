import { AppSettings } from "../models/app-settings";

import * as Settings from "../actions/settings";

export interface IState {
	urls: uwcc.ITranslation;
}

export const initialState: IState = {
	urls: null,
};

export function reducer(state = initialState, action: Settings.Actions): IState {
	switch (action.type) {
		case Settings.GET_SETTINGS_SUCCESS: {
			return {
				...state,
				urls: action.payload.urls,
			};
		}

		default: {
			return {...state};
		}
	}
}

export const getUrls = (state: IState) => state.urls;


import {filter, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Store } from "@ngrx/store";
import * as jwt_decode from "jwt-decode";
import { Observable } from "rxjs/Rx";

import { Role } from "../../core/models/role";
import { User } from "../../core/models/user";
import { ApiService } from "../../core/services/api.service";
import { DataService } from "../../core/services/data.service";
import { StorageService } from "../../core/services/storage.service";
import * as Auth from "../actions";
import { LoginDialogComponent } from "../components/dialogs/login-dialog/login-dialog.component";
import { AuthUser } from "../models/auth-user";
import * as fromAuth from "../reducers";
import { IState } from "../reducers/auth.reducers";

@Injectable()
export class AuthService {
	public token$: Observable<string>;
	public roles$: Observable<Role[]>;
	public root$: Observable<string>;
	public loggedIn$: Observable<boolean>;

	// TODO: Replace DataService dependency with this class...
	// This should act as the "DataService" for any Auth related data.
	constructor(
		private dataService: DataService,
		private api: ApiService,
		private storage: StorageService,
		private store: Store<fromAuth.IAuthState>,
		private dialog: MatDialog,
	) {
		this.loggedIn$ = this.store.select(fromAuth.getLoggedIn);
		this.token$ = this.store.select(fromAuth.getToken);
		this.roles$ = this.store.select(fromAuth.getRoles);
		this.root$ = this.store.select(fromAuth.getRoot);

		this.token$.pipe(filter(t => !!t)).subscribe(token => {
			// The token _may_ be bad if tampered in local storage.
			try {
				const jwt = jwt_decode(token) as IUwccJwt;
				this.setExpiryWarning(jwt.exp * 1000);
			} catch (error) {
				console.error(error);
			}
		});
	}

	public authenticate(payload: uwcc.IAuthenticatePayload): Observable<AuthUser> {
		return this.dataService.login(payload);
	}

	public fetchUserDetails(id: string): Observable<{ user: User; roles: Role[] }> {
		return this.api.getUserDetails(id).pipe(map(res => {
			return {
				user: new User(res.data.user),
				roles: res.data.roles.map(role => new Role(role)),
			};
		}));
	}

	public logout(): void {
		this.store.dispatch(new Auth.Logout());
	}

	public authenticateFailure(): void {
		this.store.dispatch(new Auth.Logout());
		this.store.dispatch(new Auth.LoginRedirect(null));
	}

	public loginRedirect(path: string): void {
		this.store.dispatch(new Auth.LoginRedirect(path));
	}

	public loginRedirectTo() {
		this.store
			.select(fromAuth.selectAuthStatusState).pipe(
			filter(auth => auth.loggedIn && !!auth.roles))
			.subscribe(auth => {
				this.store.dispatch(new Auth.LoginRedirectTo(this.determineRoot(auth)));
			});
	}

	public rehydrate(): void {
		const token = this.storage.token;
		const user = this.storage.user;

		if (token && user) {
			this.store.dispatch(new Auth.SetToken(token));
			this.store.dispatch(new Auth.GetUser(user));
		}
	}

	public showLoginDialog(): void {
		const dialog = this.dialog.open(LoginDialogComponent, { disableClose: true });

		dialog.afterClosed().subscribe((body: uwcc_api.ILoginTokenBody) => {
			this.store.dispatch(new Auth.RefreshLogin({ body }));
		});
	}

	private determineRoot(auth: IState): string {
		if (auth.root) {
			return auth.root;
		}

		// Check if Admin
		if (auth.roles.some(r => User.AdminRoles.includes(r.name))) {
			return User.Roles.Administrator;
		}
	}

	/**
	 * Display login dialog once expired
	 *
	 * @private
	 * @param {number} exp Number of milliseconds to expiry
	 * @memberof AuthService
	 */
	private setExpiryWarning(exp: number): void {
		const now = new Date().getTime();
		const timeout = exp - now;
		console.warn(`Token will expire on ${new Date(exp)}`, timeout);
		const to = setTimeout(() => this.showLoginDialog(), timeout);
	}
}

export interface IUwccJwt {
	sub: number;
	iss: string;
	iat: number;
	exp: number; // in Seconds
	nbf: number;
	jti: string;
}

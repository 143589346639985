
import {take, map, filter} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { AuthService } from "../services/auth.service";

@Injectable()
export class RoleGuard implements CanActivate {
	constructor(private auth: AuthService) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const validRoles = next.data.roles;

		return this.auth.roles$.pipe(
			filter(roles => !!roles),
			map(roles => {
				if (!this.roleMatch(validRoles, roles.map(r => r.name))) {
					return false;
				} else {
					return true;
				}
			}),
			take(1),);
	}

	private roleMatch(validRoles: string[], userRoles: string[]): boolean {
		return validRoles.some(role => userRoles.includes(role));
	}
}

import { LocalizeRouterService } from "localize-router";

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ConfigService } from "../core/services/config.service";

@Injectable()
export class LanguageService {
	private currentLang: string;

	constructor(private config: ConfigService, private translate: TranslateService, private localize: LocalizeRouterService) {
		this.setLanguage(this.config.app.system.i18n.lang);
	}

	public setLanguage(lang: string): void {
		this.currentLang = lang;

		this.translate.setDefaultLang(this.currentLang);
		this.translate.use(this.currentLang);
		this.localize.changeLanguage(this.currentLang);
		this.localize.init();

		const htmlEl: HTMLElement = document.getElementById("html-root");
		htmlEl.lang = this.currentLang;
	}

	public getLanguage(): string {
		return this.currentLang;
	}
}

import { Injectable } from "@angular/core";

import { ConfigService } from "./config.service";

@Injectable()
export class StorageService {
	private prefix: string;
	private keys: string[];

	constructor(private config: ConfigService) {
		this.prefix = `${this.config.app.system.storage._prefix}`;
		this.keys = Object.keys(this.config.app.system.storage);
	}

	public clear(): void {
		this.keys.forEach((key: string) => {
			this.delete(this.config.app.system.storage[key]);
		});
	}

	public get token(): string {
		return this.fetch(this.config.app.system.storage.token);
	}

	public set token(token: string | null) {
		token
			? this.store(this.config.app.system.storage.token, token)
			: this.delete(this.config.app.system.storage.token);
	}

	public get user(): string {
		return this.fetch(this.config.app.system.storage.user);
	}

	public set user(user: string | null) {
		user
			? this.store(this.config.app.system.storage.user, user)
			: this.delete(this.config.app.system.storage.user);
	}

	private fetch(key: string): string {
		return localStorage.getItem(`${this.prefix}${key}`);
	}

	private store(key: string, value: string): void {
		localStorage.setItem(`${this.prefix}${key}`, value);
	}

	private delete(key: string): void {
		localStorage.removeItem(`${this.prefix}${key}`);
	}
}

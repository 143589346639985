import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";

@Component({
	selector: "uwcc-paginator",
	templateUrl: "./paginator.component.html",
	styleUrls: ["./paginator.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, OnChanges {
	@Input() public pagination: uwcc.IPagination;
	public pages: number[];

	public get hasPrevPage(): boolean {
		if (!this.pagination) { return false; }
		return this.pagination.current_page > 1 ? true : false;
	}

	public get hasNextPage(): boolean {
		if (!this.pagination) { return false; }
		return this.pagination.current_page < this.pagination.total_pages ? true : false;
	}

	@Output() private page: EventEmitter<number>;

	constructor() {
		this.page = new EventEmitter();
	}

	public ngOnInit(): void {}

	public ngOnChanges(): void {
		if (!this.pagination) {
			return;
		}

		this.setPages();
	}

	public setPage(idx: number): void {
		this.page.emit(idx);
	}

	private setPages(): void {
		const pages = [];
		const range = 3;

		const low = [1, this.pagination.current_page - range, this.pagination.total_pages].sort((a, b) => a - b)[1];
		const high = [1, this.pagination.current_page + range, this.pagination.total_pages].sort((a, b) => a - b)[1];

		for (let i = low; i <= high; i++) {
			pages.push(i);
		}

		this.pages = [...pages];
	}
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "newlineToBr",
})
export class NewlineToBrPipe implements PipeTransform {
	transform(value: string, args: string[]): any {
		const replacedVal = value.replace(/(?:\r\n|\r|\n)/g, "<br>");
		return replacedVal;
	}
}

import { Component, OnInit } from "@angular/core";

import { AuthService } from "../services/auth.service";

@Component({
	selector: "uwcc-logout",
	templateUrl: "./logout.component.html",
	styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
	constructor(private authService: AuthService) {}

	public ngOnInit(): void {
		this.authService.logout();
	}
}

import {
	HttpHandler,
	HttpHeaderResponse,
	HttpInterceptor,
	HttpProgressEvent,
	HttpRequest,
	HttpSentEvent,
	HttpUserEvent,
} from "@angular/common/http";
import { HttpResponse } from "@angular/common/http/src/response";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs/Rx";

import { ConfigService } from "../../core/services/config.service";
import { AuthService } from "../services/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private token: string;
	private authService: AuthService;

	constructor(private injector: Injector, private config: ConfigService) {}

	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
		// Otherwise set Authorization token...
		this.authService = this.injector.get(AuthService);
		this.authService.token$.subscribe(token => (this.token = token));

		// Bail if requesting anything but API URL.
		const re: RegExp = new RegExp(this.config.app.system.api.url);
		if (!req.url.match(re) || !this.authService || !this.token) {
			return next.handle(req);
		}

		req = req.clone({
			setHeaders: {
				Authorization: `Bearer ${this.token}`,
			},
		});
		return next.handle(req);
	}
}

import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromAuth from "./auth.reducers";
import * as fromLogin from "./login.reducers";

export const FEATURE = "AUTH";

export interface IAuthState {
	status: fromAuth.IState;
	login: fromLogin.IState;
}

export const reducers = {
	status: fromAuth.reducer,
	login: fromLogin.reducer,
};

export const selectAuthState = createFeatureSelector<IAuthState>(FEATURE);
export const selectAuthStatusState = createSelector(selectAuthState, state => state.status);
export const getLoggedIn = createSelector(selectAuthStatusState, fromAuth.getLoggedIn);
export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);
export const getRoles = createSelector(selectAuthStatusState, fromAuth.getRoles);
export const getRoot = createSelector(selectAuthStatusState, fromAuth.getRoot);
export const getToken = createSelector(selectAuthStatusState, (status) => status.token);

export const selectLoginPageState = createSelector(selectAuthState, (state: IAuthState) => state.login);
export const getLoginPending = createSelector(selectLoginPageState, fromLogin.getPending);
export const getLoginError = createSelector(selectLoginPageState, fromLogin.getError);
